import React, { useContext } from "react"
import { Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import { useQuery } from "react-query"

// Layout
import KnockOut from "../components/layout/KnockOut"
import { Form, Fieldset, FormTextArea, Button } from "../components/forms"

// Utility
import { Pods } from "../config/db"
import { AuthContext } from "../context/AuthContext"
import withWindow from "../helpers/withWindow"
import useQueryParams from "../hooks/useQueryParams"
import { sendEmail } from "../helpers/emailHelper"

const initialValues = {
  reportDetails: "",
}

const Report = () => {
  const { hasPod } = useContext(AuthContext)

  const params = useQueryParams()
  const id = params.get("id")

  const { data: pod, isLoading } = useQuery("Report - fetchPod", async () =>
    Pods.fetchById(id)
  )
  const reportedPods = localStorage.getItem("reportedPods")

  const handleSubmit = report => {
    const reportInfo = {
      ...pod,
      ...report,
    }
    const recipient = process.env.GATSBY_REPORT_EMAIL
    sendEmail("reported", reportInfo, recipient)

    if (JSON.parse(reportedPods)) {
      let reported = JSON.parse(reportedPods)
      reported.push(id)
      localStorage.setItem("reportedPods", JSON.stringify(reported))
    } else {
      localStorage.setItem("reportedPods", JSON.stringify([id]))
    }

    navigate(`/inform?mode=reported`)
  }

  if (hasPod?.id === id) {
    navigate(`/profile?id=${hasPod.id}`)
  }

  if (!isLoading && !pod) {
    navigate("/404")
    return null
  }

  if (JSON.parse(reportedPods)?.includes(id)) {
    navigate(`/inform?mode=alreadyReported`)
  }

  return (
    <>
      <Helmet>
        <title>Reporting &quot;{pod ? pod.podName : "pod"}&quot;</title>
      </Helmet>
      {isLoading ? (
        <KnockOut />
      ) : (
        <div className="[ wrapper ] [ pod-profile ] ">
          <Link to={`/profile?id=${pod.id}`}>← Pod profile</Link>
          <h2>Reporting the pod &quot;{pod.podName}&quot;</h2>
          <Form initialValues={initialValues} onSubmit={handleSubmit}>
            <div className="[ flow ]">
              <header className=" [ shadow-box ] [ pod-header ]">
                <Fieldset heading="Reporting pod">
                  <ul className="culture-input">
                    <FormTextArea
                      label="Include some information on why you're reporting this pod"
                      name="report"
                    />
                  </ul>
                </Fieldset>
              </header>

              <p className="text-center">
                We&apos;ll review all reports as soon as possible and will take
                actions as necessary.
              </p>
              <Button title="Send report" />
            </div>
          </Form>
        </div>
      )}
    </>
  )
}

export default withWindow(Report)
